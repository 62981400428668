import { Icon, IconProps } from "@chakra-ui/react"

export const LightningIcon = (props: IconProps) => (
  <Icon viewBox="1000 400 2000 2400" {...props}>
    <path
      fill="currentColor"
      d="M 1210.31, 1714.2 L 878.047, 1714.2 C 871.555, 1714.2 865.498, 1710.93 861.935, 1705.51 C 858.372, 1700.08 857.781, 1693.22 860.363, 1687.27 L 1239.52, 812.52 C 1241.9, 807.024 1247.81, 803.956 1253.68, 805.173 C 1259.54, 806.389 1263.75, 811.556 1263.75, 817.545 L 1263.75, 1506.29 L 1596.01, 1506.29 C 1602.5, 1506.29 1608.56, 1509.56 1612.12, 1514.99 C 1615.68, 1520.42 1616.27, 1527.27 1613.69, 1533.23 L 1234.54, 2407.98 C 1232.15, 2413.47 1226.24, 2416.54 1220.38, 2415.32 C 1214.51, 2414.11 1210.31, 2408.94 1210.31, 2402.95 L 1210.31, 1714.2 Z"
    />
  </Icon>
)
