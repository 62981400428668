export const upgradeOptions = [
  "Costly utility upgrades",
  "Long utility upgrade delays",
  "No intelligence or management features",
]

export const SPANOptions = [
  "Avoid engaging with the utility altogether",
  "Get the electric appliances you want",
  "Gain circuit-level visibility and control, both on and off-grid ",
]

export const howPowerUpWorks_Section1 =
  "As you add electric appliances, your home could occasionally" +
  " need more power than your home's wires can support. Your" +
  " electrician or inspector may require you to enlarge your wires" +
  " and panel to support the rare, short high power events that" +
  " might occur."

export const howPowerUpWorks_Section2 =
  "PowerUp saves you money and time by eliminating the need for" +
  " service upgrades in most homes. Instead of installing bigger" +
  " wires and panels, PowerUp uses smart appliance control to" +
  " limit power to what your home can handle."

export const howPowerUpWorks_Section3 =
  "Because high power events are rare and tend to last just a few" +
  " minutes, PowerUp can manage many appliances with minimal" +
  " disruption. EV chargers and tank electric water heaters are" +
  " great examples of appliances PowerUp can manage gracefully."

export const howPowerUpWorks_Section4 =
  "PowerUp lets you modify the appliances it will manage and in" +
  " what order, so you can shape your experience to fit your" +
  " unique lifestyle."

export const exploreLandingFooter =
  "* * Up to 48M US homes may need a service upgrade, more than half" +
  ' of the single family stock; Pecan Street Inc. "Addressing an' +
  " Electrification Roadblock: Residential Electric Panel" +
  ' Capacity." Pecan Street Inc., 15 July 2021,' +
  " https://www.pecanstreet.org/publications/addressing-an-electrification-roadblock-residential-electric-panel-capacity/."
