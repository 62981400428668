import { Box, Button, Heading, Text, Theme, useTheme } from "@chakra-ui/react"
import Image from "next/image"
import { useRouter } from "next/navigation"

import houseImage from "@/public/images/Span-outage-house.png"

type PowerUpHeroImageProps = {
  isButtonShown: boolean
  isComingSoonBannerShown?: boolean
  downloadHeight?: string
  forceDesktopSize?: boolean
  unoptimized?: boolean
}

const PowerUpHeroImage = ({
  isButtonShown,
  isComingSoonBannerShown,
  downloadHeight,
  forceDesktopSize,
  unoptimized = false,
}: PowerUpHeroImageProps) => {
  const theme = useTheme() as Theme
  const router = useRouter()

  return (
    <Box bg="#060C11" style={{ position: "relative" }}>
      <Image
        id={"span_home_hero_image"}
        data-cy="span-home-hero-image"
        alt="SPAN-Home-Image"
        src={houseImage}
        quality={100}
        style={{
          objectFit: "cover",
          height: downloadHeight ? downloadHeight : "100vh",
          minHeight: "550px",
          opacity: 0.5,
        }}
        unoptimized={unoptimized} // optional param to 'unoptimize' the image for the Takeaway download page
      />
      <Box
        mx={forceDesktopSize ? "3rem" : { base: "1rem", sm: "3rem" }}
        top={"50%"}
        transform={"translate(0, -50%)"}
        position={"absolute"}
      >
        <Heading
          color={"white"}
          fontSize={forceDesktopSize ? "8xl" : { base: "12vw", md: "8xl" }}
          fontWeight={"semibold"}
        >
          SPAN
        </Heading>
        <Box display={"inline-flex"}>
          <Heading
            color={"white"}
            fontSize={forceDesktopSize ? "8xl" : { base: "12vw", md: "8xl" }}
            fontWeight={"semibold"}
          >
            PowerUp
          </Heading>
          <Heading
            color={"white"}
            fontSize={forceDesktopSize ? "3xl" : { base: "3vw", md: "3xl" }}
            fontWeight={"semibold"}
            marginTop={2}
          >
            TM
          </Heading>
        </Box>
        <Text
          mt="2rem"
          fontSize={
            forceDesktopSize
              ? "lg"
              : { base: "4vw", xs: "xs", md: !isButtonShown ? "lg" : "sm" }
          }
          color={"white"}
          maxWidth={forceDesktopSize ? "50%" : { base: "100%", sm: "50%" }}
          opacity={1}
        >
          Installing new electric appliances like induction stoves, heat pumps
          and EV chargers often triggers the need for a service upgrade to
          increase power coming from the utility into the home.
        </Text>
        <Text
          mt="1rem"
          fontSize={
            forceDesktopSize
              ? "lg"
              : { base: "4vw", xs: "xs", md: !isButtonShown ? "lg" : "sm" }
          }
          color={"white"}
          maxWidth={forceDesktopSize ? "50%" : { base: "100%", sm: "50%" }}
          opacity={1}
        >
          SPAN enables most homeowners to avoid service upgrades using PowerUp,
          a smart home energy management solution built into every SPAN Panel.
        </Text>
        {isButtonShown && (
          <Button
            data-cy="explore-button-one"
            mt={"2rem"}
            onClick={() => {
              router.push("/configurations/new")
            }}
            variant={"outline"}
            border="1px solid white"
            borderRadius={"20px"}
            color="white"
            size={{ xs: "sm", md: "md" }}
            _hover={{ bg: theme.colors.gray["800"] }}
          >
            Explore PowerUp
          </Button>
        )}
        {isComingSoonBannerShown && (
          <Box
            border="1px solid"
            borderColor={theme.colors.white["500"]}
            borderRadius={"20px"}
            width={"fit-content"}
            mt={"3rem"}
          >
            <Text
              color={theme.colors.white["500"]}
              py={2}
              px={4}
              fontWeight={"semibold"}
            >
              Presale Tool coming soon
            </Text>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default PowerUpHeroImage
